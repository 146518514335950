<template>
  <div id="member-subscription-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Kindly select the members below subscriptions.</h4>
        <h5>Kindly ensure that the date range is within the same year.</h5>
        Click
        <v-icon icon size="25px" color="primary"
          >mdi-account-multiple-plus</v-icon
        >button to add members
      </v-card-subtitle>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0" v-if="!LoPresidentFlag">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>
                  <h6>Zone <small>(Optional)</small></h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Lom
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> From Date</h6>
                </label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="FromDate"
                      label="From Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="FromDate"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> To Date</h6>
                </label>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="ToDate"
                      label="To Date"
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="ToDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
                <v-btn
                  v-if="ExcelDownloadFlag"
                  color="info"
                  @click.prevent="
                    generateExcel(
                      tableData,
                      ExcelFields,
                      ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="mr-4"
                  >Download</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader
            v-bind="attrs"
            type="table-tbody"
          ></v-skeleton-loader>
        </v-container>
        <v-container class="py-0" v-if="tableData.length == 0">
          <v-row wrap v-if="!StartupLoadingFlag">
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :headers="tableColumns"
                :items="tableData"
                :search="search"
                :show-select="FloatingButtonOptions.ShowSelectFlag"
                :item-key="tableOptions.ItemKey"
                :single-select="tableOptions.SingleSelectFlag"
                :items-per-page="tableOptions.ItemsPerPage"
                :footer-props="tableOptions.FooterProps"
              >
                <template v-slot:item.ReceiptFilePath="{ item }">
                  <v-tooltip bottom v-if="item.ReceiptFilePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="item.ReceiptFilePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.AddMemberFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-account-multiple-plus</v-icon
                      >
                    </template>
                    <span>Add members</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import StaffCreate from "@/view/pages/erp/staff/StaffCreate.vue";

export default {
  mixins: [common],
  components: {
    StaffCreate,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      ResultFlag: false,

      ExcelDownloadFlag: false,
      ExcelFields: {},
      ExcelFileName: "",

      tableData: [],
      tableColumns: [],
      tableOptions: {},
      selected: [],
      search: "",
      CurrentYearId: 0,

      zone: {},
      lom: {},

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      PageInfo: {},
      LoPresidentFlag: false,
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.LoPresidentFlag = tr.LoPresidentFlag;
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomCode = this.$session.get("LomId");
        this.LomCode = LomCode == (null || undefined) ? 0 : LomCode;
        console.log("LomCode=" + LomCode);

        var ZoneCode = this.$session.get("ZoneId");
        this.ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        this.ZoneCode = ZoneCode;

        if (this.LoPresidentFlag) {
          this.searchForm();
        } else {
          this.getZoneCodeOptions();
        }
      }
    },
    SearchId: function () {
      console.log("watch SearchId");
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "sma_receipt",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var url = "/senior/Members/assign";
        var id = tr.OrderId;
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one receipt to preview";
        this.toast("error", message);
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.tableData1 = [];
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.ZoneCodeOptions = [];
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    searchForm() {
      console.log("searchForm called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/senior/members/receipt/list";
      if (this.LoPresidentFlag) {
        var upload = {
          UserInterface: 1,
          Lom: this.LomCode,
          Zone: this.ZoneCode,
        };
      } else {
        var upload = {
          UserInterface: 2,
          Lom: this.LomCode,
          Zone: this.ZoneCode,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
        };
      }
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.tableData = [];
      this.ResultFlag = false;
      this.LoadingFlag = true;
      this.StartupLoadingFlag = true;
      this.ExcelDownloadFlag = false;
      this.ExcelFileName = "";

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.ResultFlag = true;
          thisIns.LoadingFlag = false;
          thisIns.StartupLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            var options = records.TableOptions;
            console.log({ options });
            thisIns.tableColumns = records.TableHeader;
            thisIns.tableData = records.TableData;
            thisIns.tableOptions = records.TableOptions;
            thisIns.ExcelDownloadFlag = options.ExcelDownloadFlag;
            thisIns.ExcelFileName = options.ExcelFileName;
            thisIns.ExcelFields = options.ExcelFields;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.toast("error", error);
          thisIns.ResultFlag = true;
          thisIns.LoadingFlag = false;
          thisIns.StartupLoadingFlag = false;
        });
    },
  },
  beforeMount() {
    var todayDate = new Date().toISOString().slice(0, 10);
    console.log(todayDate);

    this.FromDate = todayDate;
    this.ToDate = todayDate;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#member-subscription-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>